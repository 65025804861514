import React, {useContext, useState, useCallback, useEffect} from 'react';
import {Stack, CircularProgress, DialogActions, DialogContent, DialogTitle, Button, Grid2 as Grid, Typography, Card} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {collection, doc, query, where, updateDoc} from 'firebase/firestore';
import {useForm, FormProvider} from 'react-hook-form';
import {useSnackbar} from 'notistack';
import moment from 'moment';
import {get} from 'lodash';
import {OfficerRanks} from '@embertracking/common';
import CheckIcon from '@mui/icons-material/Check';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {db} from '-/firebase';

import {getCollection, populateKeyFromCollection, ensureJSDates, ensureMomentDates} from '-/data/utils';

import {UserContext} from '-/contexts/User';

import DatePickerField from '-/form/DatePickerField';
import UserAutocompleteField from '-/form/UserAutocompleteField';
import ResponsiveDialog from '-/components/ResponsiveDialog';

const ApproveDialog = function({onSubmit: submitHandler, open, handleClose}) {
    const {currentUser} = useContext(UserContext);
    const {uid: currentUserUid} = currentUser || {};
    const dateFormatShort = get(currentUser, 'settings.dateFormatShort') || 'MMMM YYYY';

    const defaultValues = {
        approvedAt: moment(),
        approvedBy: currentUserUid
    };

    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues,
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await submitHandler(data);
        
        if (result) {
            handleClose();
            reset(defaultValues);
        }

        setLoading(false);
    }, [handleClose, submitHandler, reset]);

    useEffect(() => {
        reset(defaultValues);
    }, [open]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>Approve Apparatus</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid size={{xs: 12, sm: 6}}>
                            <DatePickerField
                                name="approvedAt"
                                views={['year', 'month']}
                                format={dateFormatShort}
                                label="Date"
                                fullWidth
                                required
                                disabled={loading}
                            />
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <UserAutocompleteField
                                name="approvedBy"
                                label="Approved By"
                                fullWidth
                                required
                                allowedRanks={OfficerRanks}
                                disabled={loading}
                                multiple={false}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <LoadingButton loading={loading} variant="contained" type="submit" disabled={loading}>Approve</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};

export default function UserDriverTraining(props) {
    const {user = {}} = props;
    const {uid} = user;
    const {enqueueSnackbar} = useSnackbar();
    const [apparatus, setApparatus] = useState([]);
    const [approving, setApproving] = useState(false);
    const {approvedApparatus = []} = user || {};
    const {currentUser, users} = useContext(UserContext);
    const dateFormatShort = get(currentUser, 'settings.dateFormatShort') || 'MMMM YYYY';

    const [loading, setLoading] = useState(true);

    const fetch = useCallback(async() => {
        if (!uid) {
            return;
        }
        
        setLoading(true);

        try {
            const driverLogs = await getCollection(db, query(collection(db, 'driverLogs'), where('user', '==', uid)));

            let apparatus = await getCollection(db, 'apparatus');
            apparatus = await populateKeyFromCollection(db, apparatus, 'station', 'stations');
            apparatus = apparatus.map(apparatus => {
                const {uid} = apparatus;

                return {
                    ...apparatus,
                    driverLogs: driverLogs.filter(log => log.apparatus === uid)
                };
            });

            setApparatus(apparatus);
        } catch(e) {
            enqueueSnackbar('Failed to fetch permissions', {variant: 'error'});
        }

        setLoading(false);
    }, [uid]);

    useEffect(() => {
        fetch();
    }, [uid]);

    const handleApprove = async data => {
        const {approvedAt, approvedBy} = data;
        const {uid: apparatusUid} = approving;

        const newApprovedApparatus = [...approvedApparatus].filter(apparatus => apparatus.uid !== uid);
        newApprovedApparatus.push({
            apparatusUid,
            approvedAt,
            approvedBy
        });

        try {
            const ref = doc(db, 'users', uid);
            await updateDoc(ref, ensureJSDates({
                approvedApparatus: newApprovedApparatus
            }));
        } catch(e) {
            enqueueSnackbar(e.message || 'Failed to approve apparatus', {variant: 'error'});
            return false;
        }

        return true;
    };

    return (
        <>
            {loading && (
                <Stack sx={{display: 'flex', alignItems: 'center'}}>
                    <CircularProgress />
                </Stack>
            )}

            <ApproveDialog open={!!approving} onSubmit={handleApprove} handleClose={() => setApproving(false)} />

            <Grid container spacing={1}>
                {apparatus.map(apparatus => {
                    const {uid, tag, driverLogs = []} = apparatus;
                    const isApproved = approvedApparatus.find(approved => approved.apparatusUid === uid);
                    const totalHours = driverLogs.reduce((total, log) => total + log.hours, 0);

                    const {approvedAt, approvedBy: approvedByUid} = ensureMomentDates(isApproved || {});
                    const approvedBy = users.find(user => user.uid === approvedByUid);
                    const {fullName: approvedByName} = approvedBy || {};

                    return (
                        <Grid size={12} key={`apparatus-${uid}`}>
                            <Card sx={{px: 2, py: 1}} severity={isApproved ? 'success' : 'info'}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    {isApproved && <TaskAltIcon />}
                                    <Stack direction="column" sx={{flex: 1}}>
                                        <Typography variant="h6">{tag}</Typography>
                                        {!isApproved && <Typography variant="caption"><strong>{totalHours}</strong> hours logged</Typography>}
                                    </Stack>

                                    {isApproved ? (
                                        <Stack direction="column" alignItems="flex-end">
                                            <Typography variant="caption">Approved by <strong>{approvedByName}</strong></Typography>
                                            <Typography sx={{opacity: 0.8}} variant="caption">{approvedAt.format(dateFormatShort)}</Typography>
                                        </Stack>
                                    ) : (
                                        <Button startIcon={<CheckIcon />} variant="contained" color="primary" size="small" disabled={loading} onClick={() => setApproving(apparatus)}>Approve</Button>
                                    )}
                                </Stack>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
}