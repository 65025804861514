import React from 'react';
import {useController} from 'react-hook-form';
import {TimeField} from '@mui/x-date-pickers/TimeField';
import {capitalize} from 'lodash';
import moment from 'moment';

export default function ControlledTimeField(props) {
    const {name, label, required, rules: rawRules, helperText, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value: rawValue, ...restField} = field;
    
    let value = rawValue;
    if (value && typeof value === 'string') {
        value = moment(`1970-01-01T${value}`);
    }

    return (
        <TimeField
            variant="outlined"
            label={fieldLabel}
            required={required}
            format="HH:mm"
            value={value}
            {...restField}
            error={!!error}
            helperText={error && helperText !== false ? error.message : helperText}
            {...rest}
        />
    );
};
