import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Button, Box, AvatarGroup, useMediaQuery, alpha} from '@mui/material';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import moment from 'moment';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {get, castArray} from 'lodash';
import {collectionGroup, getDocs, query} from 'firebase/firestore';
import {useSnackbar} from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {red, green} from '@mui/material/colors';
import {useTheme, styled} from '@mui/material/styles';
import Tab, {tabClasses} from '@mui/material/Tab';
import {tabsClasses} from '@mui/material/Tabs';
import UpdateIcon from '@mui/icons-material/Update';

import {UserContext} from '-/contexts/User';
import {db} from '-/firebase';

import {hasPermission, populateUsers, processRawDocs} from '-/data/utils';

import UserAvatar from '-/components/UserAvatar';

// import TaskDialog from './TaskDialog';
// import TaskCompletionDialog from './TaskCompletionDialog';

const TabItem = styled(Tab)(({theme}) => ({
    border: `1px solid ${(theme.vars || theme).palette.divider}`,
    marginRight: 6,
    marginBottom: 0,
    // fontSize: '0.8rem',
    // minHeight: 30,
    // padding: 0,
    // borderTopLeftRadius: 4,
    // borderTopRightRadius: 4,
    borderBottomWidth: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    // '&:not(:first-of-type)': {
    //     marginLeft: -1,
    // },
    opacity: 0.8,
    background: '#f7f7f7',
    [`&.${tabClasses.selected}`]: {
        background: '#ffffff',
        opacity: 1,
        fontWeight: 'bold'
    },
    '& .MuiTab-icon:hover': {
        background: 'transparent'
    }
}));

// const useTasks = () => {
//     const params = useParams();
//     const {users} = useContext(UserContext);
//     const {enqueueSnackbar} = useSnackbar();
//     const [loadingTasks, setLoadingTasks] = useState(true);
//     const [loadingParent, setLoadingParent] = useState(true);
//     const [tasks, setTasks] = useState([]);
//     const [parent, setParent] = useState(null);

//     const parentRefArgs = useMemo(() => {
//         const {id: uid, ...otherParams} = params;
//         let refArgs = [db];

//         Object.entries(otherParams).forEach(([key, param]) => {
//             if (key !== '*' && param) {
//                 refArgs.push('gear', param);
//             }
//         });

//         // we only need to add the UID if the current page is a gear page. if it an item page, it isnt needed
//         const url = params['*'] || '';
//         const split = url.split('/').slice(-2);
//         const isGearCollection = split.includes('gear');

//         if (isGearCollection) {
//             refArgs.push('gear', uid);
//         } else {
//             refArgs.push('items', uid);
//         }

//         return refArgs;
//     }, [db, params]);

//     const refArgs = useMemo(() => {
//         let refArgs = [...parentRefArgs];
//         refArgs.push('tasks');

//         return refArgs;
//     }, [parentRefArgs]);

//     useEffect(() => {
//         setLoadingTasks(false);

//         const ref = collection(...refArgs);
//         const unsubscribe = onSnapshot(ref, async snapshot => {
//             const rows = populateDocsWithUsers(processRawDocs(snapshot), users);
//             setTasks(rows);

//             setLoadingTasks(false);
//         }, e => {
//             enqueueSnackbar(e.message, {variant: 'error'});

//             console.warn('Error getting tasks', refArgs);

//             setLoadingTasks(false);
//         });
        
//         return () => {
//             unsubscribe();
//         };
//     }, [db, refArgs, enqueueSnackbar, users]);

//     useEffect(() => {
//         setLoadingParent(true);

//         const ref = doc(...parentRefArgs);
//         const unsubscribe = onSnapshot(ref, async snapshot => {
//             let row = processRawDoc(snapshot);

//             setParent(row);

//             setLoadingParent(false);
//         }, e => {
//             enqueueSnackbar(e.message, {variant: 'error'});

//             console.warn('Error getting tasks', parentRefArgs);

//             setLoadingParent(false);
//         });
        
//         return () => {
//             unsubscribe();
//         };
//     }, [db, parentRefArgs, enqueueSnackbar]);

//     const loading = loadingTasks || loadingParent;

//     return {
//         tasks,
//         parent,
//         loading,
//         refArgs
//     };
// };

export default function(props) {
    const [open, setOpen] = useState(false);
    const [completion, setCompletion] = useState(false);
    const [row, setRow] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const {currentUser} = useContext(UserContext);
    const {uid: userUid} = currentUser;
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const {enqueueSnackbar} = useSnackbar();
    const theme = useTheme();
    const isAtLeastMedium = useMediaQuery(theme.breakpoints.up('sm'));
    // const {tasks, loading, refArgs} = useTasks();
    // const tasks = [];
    const loading = false;

    const [tasks, setTasks] = useState([]);

    const onRowClick = useCallback(params => {
        const {row} = params;
        setRow(row);

        setOpen(true);
    }, []);

    const handleAdd = useCallback(() => {
        setRow(null);
        setOpen(true);
    }, []);

    const fetch = async() => {
        try {
            // const tasks = query(collectionGroup(db, 'tasks'));
            const ref = query(collectionGroup(db, 'tasks'));
            const raw = await getDocs(ref);

            const newTasks = await populateUsers(db, processRawDocs(raw));
            console.log(newTasks);
            setTasks(newTasks);
        } catch(e) {
            console.log(e);
            enqueueSnackbar(e.message, {variant: 'error'});
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    // const onAdd = useCallback(async data => {
    //     try {
    //         const ref = collection(...refArgs);

    //         const record = {
    //             ...omit(pickBy(data), ['uid', 'id']),
    //             createdAt: new Date(),
    //             user: userUid
    //         };

    //         await addDoc(ref, record);
    //         return record;
    //     } catch (e) {
    //         enqueueSnackbar(e.message, {variant: 'error'});
    //     }
    // }, [enqueueSnackbar, refArgs, userUid]);

    const handleComplete = useCallback(async row => {
        // const {uid, recurring, assignee, frequency, date: dueDate, completedBy, completedNote, files: rawFiles} = row;

        // let files = [];
        // if (rawFiles) {
        //     for (const file of rawFiles) {
        //         const {name} = file;
        //         const path = `${[...refArgs].slice(1).join('/')}/${name}`;
        //         const fileRef = ref(storage, path);

        //         await uploadBytes(fileRef, file);
                
        //         files.push({
        //             name,
        //             url: await getDownloadURL(fileRef)
        //         });
        //     }
        // }

        // await updateDoc(doc(...refArgs, uid), {
        //     status: 'COMPLETE',
        //     files,
        //     completedNote,
        //     completedBy: get(completedBy, 'uid', completedBy),
        //     completedAt: new Date()
        // });

        // if (recurring && frequency) {
        //     const {name} = row;
        //     const type = frequency === 'YEARLY' ? 'year' : 'month';
        //     const date = moment(dueDate).add(1, type).toDate();

        //     const record = {
        //         name,
        //         assignee: assignee.uid || assignee,
        //         date,
        //         createdAt: new Date(),
        //         user: userUid,
        //         status: 'OPEN'
        //     };

        //     await addDoc(collection(...refArgs), record);
        // }

        // enqueueSnackbar('Task marked as complete', {variant: 'success'});

        // return true;
    }, [enqueueSnackbar, userUid]);

    const onUpdate = async data => {
        // const {uid, assignee, user, ...rest} = data;
        // const toUpdate = rest;

        // if (assignee) {
        //     toUpdate.assignee = assignee.uid || assignee;
        // }

        // if (user) {
        //     toUpdate.user = user.uid || user;
        // }

        // const ref = doc(...refArgs, uid);

        // if (Object.keys(toUpdate)) {
        //     await updateDoc(ref, {
        //         ...toUpdate,
        //         updatedAt: new Date()
        //     });
        // }

        return true;
    };

    const onDelete = data => {
        // const {uid} = data;

        // return new Promise(resolve => {
        //     const onDelete = async() => {
        //         const ref = doc(...refArgs, uid);
        //         await deleteDoc(ref);

        //         resolve(true);
        //     };

        //     enqueueSnackbar('Are you sure you want to delete this item?', {
        //         variant: 'warning',
        //         action: key => {
        //             return (
        //                 <>
        //                     <Button onClick={() => {
        //                         closeSnackbar(key);
        //                         onDelete();
        //                     }}>
        //                         Delete
        //                     </Button>
        //                     <Button onClick={() => {
        //                         resolve(true);
        //                         closeSnackbar(key);
        //                     }}>
        //                         Cancel
        //                     </Button>
        //                 </>
        //             );
        //         }
        //     });
        // });
    };

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Created',
            sortable: true,
            valueFormatter: value => {
                return value ? moment(value).format(dateFormat) : '-';
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'assignee',
            headerName: 'Assignee',
            width: 100,
            renderCell: params => {
                const {value} = params || {};
                const users = castArray(value);

                const avatars = users.map((member, index) => {
                    const {id} = member || {};

                    return (
                        <UserAvatar key={`${id}-${index}`} user={member} />
                    );
                });

                return (
                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <AvatarGroup>
                            {avatars}
                        </AvatarGroup>
                    </Box>
                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            width: 100,
            valueFormatter: value => {
                return value;
                // return TaskStatuses[value] || '-';
            }
        },
        {
            field: 'date',
            headerName: 'Due By',
            sortable: true,
            width: 140,
            valueFormatter: (value, row) => {
                const {date, dateType} = row || {};

                if (!date) {
                    return '-';
                }

                if (dateType === 'month') {
                    return moment(date).startOf('month').format('MMMM YYYY');
                }

                if (dateType === 'year') {
                    return `End of ${moment(date).startOf('year').format('YYYY')}`;
                }

                return moment(date).startOf('day').format(dateFormat);
            }
        },
        {
            type: 'actions',
            field: 'actions',
            width: 50,
            getActions: params => {
                const {row} = params;
                const {status, assignee} = row || {};

                if (status === 'COMPLETE' || (!hasPermission(currentUser) && !hasPermission(currentUser, 'tasks.write') && assignee !== userUid)) {
                    return [];
                }

                return [
                    <GridActionsCellItem
                        key="task-actions-complete"
                        icon={<CheckIcon />}
                        onClick={() => setCompletion(row)}
                        label="Mark Complete"
                    />
                ];
            }
        }
    ];

    const completedColumns = [
        {
            field: 'completedAt',
            headerName: 'Completed',
            sortable: true,
            width: 120,
            valueFormatter: value => {
                return value ? moment(value).fromNow() : '-';
            }
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1
        },
        {
            field: 'completedBy',
            headerName: 'Completed By',
            width: 140,
            renderCell: params => {
                const {value} = params || {};
                const users = castArray(value);

                const avatars = users.map((member, index) => {
                    const {id} = member || {};

                    return (
                        <UserAvatar key={`${id}-${index}`} user={member} />
                    );
                });

                return (
                    <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <AvatarGroup>
                            {avatars}
                        </AvatarGroup>
                    </Box>
                );
            }
        }
    ];

    if (tasks.length === 0 && !hasPermission(currentUser, 'tasks.write')) {
        return null;
    }

    const filteredRows = tasks.filter(row => {
        const {status} = row || {};

        if (tabIndex === 0) {
            return status !== 'COMPLETE';
        }

        return status === 'COMPLETE';
    });

    const activeTabText = tabIndex === 0 ? 'upcoming' : 'complete';

    return (
        <Box {...props}>
            {/* <TaskCompletionDialog open={!!completion} onClose={() => setCompletion(false)} onSubmit={handleComplete} record={completion} /> */}
            
            <TabContext value={tabIndex}>
                <TabList
                    onChange={(e, index) => setTabIndex(index)}
                    sx={{
                        minHeight: 30,
                        ml: 1,
                        [`& .${tabsClasses.indicator}`]: {
                            display: 'none'
                        }
                    }}
                >
                    <TabItem icon={<UpdateIcon />} iconPosition="start" disableRipple label="Upcoming" />
                    <TabItem icon={<CheckIcon />} iconPosition="start" disableRipple label="Complete" />
                </TabList>

                <Box sx={{display: 'flex', flexDirection: 'column', minHeight: filteredRows.length > 0 ? 'auto' : 150}}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [
                                    {field: 'date', sort: 'desc'}
                                ]
                            },
                            columns: {
                                columnVisibilityModel: {
                                    actions: hasPermission(currentUser, 'tasks.write'),
                                    createdAt: false,
                                    assignee: isAtLeastMedium,
                                    status: isAtLeastMedium
                                }
                            }
                        }}
                        localeText={{noRowsLabel: `No ${activeTabText} tasks`}}
                        loading={loading}
                        rows={filteredRows}
                        columns={(tabIndex === 0 ? columns : completedColumns).map(column => ({...column, disableColumnMenu: true}))}
                        pageSizeOptions={[]}
                        disableRowSelectionOnClick
                        disableColumnFilter
                        onRowClick={onRowClick}
                        hideFooter
                        slotProps={{
                            loadingOverlay: {
                                variant: 'linear-progress',
                                noRowsVariant: 'skeleton'
                            }
                        }}
                        getRowClassName={params => {
                            const {row} = params;
                            const {status, date, dateType} = row || {};
                            const classNames = [];

                            let isOverdue = moment(date).isBefore(moment(), 'day');
                            if (dateType === 'month') {
                                isOverdue = moment(date).isBefore(moment(), 'month');
                            } else if (dateType === 'year') {
                                isOverdue = moment(date).isBefore(moment(), 'year');
                            }

                            if (status === 'COMPLETE') {
                                return 'complete';
                            }

                            if (isOverdue) {
                                classNames.push('overdue');
                            }
        
                            return classNames.join(' ');
                        }}
                        sx={{
                            '& .MuiDataGrid-cell:focus-within': {
                                outline: 'none'
                            },
                            
                            '& .overdue': {
                                backgroundColor: `${alpha(red[100], 0.3)}`,
                                borderLeft: `4px solid ${red[500]}`
                            },
                            '& .MuiDataGrid-row:hover.overdue': {
                                backgroundColor: `${alpha(red[100], 0.4)}`
                                // borderLeft: `4px solid ${red[500]}`
                            },
                            '& .complete': {
                                backgroundColor: `${alpha(green[100], 0.3)}`,
                                borderLeft: `4px solid ${green[500]}`
                            },
                            '& .MuiDataGrid-row:hover.complete': {
                                backgroundColor: `${alpha(green[100], 0.4)}`
                                // borderLeft: `4px solid ${red[500]}`
                            }
                        }}
                    />
                </Box>
            </TabContext>

            {hasPermission(currentUser, 'tasks.write') && (
                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                    {/* <TaskDialog item={row} onSubmit={row ? onUpdate : onAdd} open={open} onDelete={row && onDelete} handleClose={() => setOpen(false)} /> */}
                    <Box sx={{flexGrow: 1}} />
                    <Button startIcon={<AddIcon />} disabled={loading} size="small" variant="outlined" onClick={handleAdd}>Add New Task</Button>
                </Box>
            )}
        </Box>
    );
};