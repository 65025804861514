import React from 'react';
import {useController} from 'react-hook-form';
import {TextField} from '@mui/material';
import {capitalize} from 'lodash';

export default function NumberField(props) {
    const {name, label, required, rules: rawRules, helperText, ...rest} = props;
    const rules = {...rawRules, valueAsNumber: true};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange, ...restField} = field;

    const handleChange = event => {
        const newValue = event.target.value;
        if (/^-?\d*\.?\d*$/.test(newValue) || newValue === '') {
            onChange(newValue === '' ? '' : Number(newValue));
        }
    };

    return (
        <TextField
            variant="outlined"
            label={fieldLabel}
            required={required}
            value={value || ''}
            onChange={handleChange}
            error={!!error}
            helperText={error && helperText !== false ? error.message : helperText}
            inputProps={{inputMode: 'decimal', pattern: '[0-9]*'}}
            {...restField}
            {...rest}
        />
    );
}
