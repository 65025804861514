import React, {useCallback, useContext, useState} from 'react';
import {Button, Tooltip, Chip, Box, Typography, DialogActions, DialogContent, DialogTitle, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import {get} from 'lodash';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {lightGreen} from '@mui/material/colors';
import {useNavigate} from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Ranks} from '@embertracking/common';

import {Skills, hasPermission} from '-/data/utils';
import {Competencies, OFCMinimumStandards, getStandardForKey} from '-/data/standards';
import UserAvatar from '-/components/UserAvatar';

import {UserContext} from '-/contexts/User';

import SmallChip from '-/components/SmallChip';
import ResponsiveDialog from '-/components/ResponsiveDialog';

import {StandardSubsection} from '-/pages/NFPA';

const Training = props => {
    const {open, onClose, field, member = {}} = props;
    const {uid: userUid, fullName, role, training = []} = member;
    const {currentUser} = useContext(UserContext);
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState({});

    let [, level, competency] = field.split('.');
    if (field.match('.levels.')) {
        [, level, , competency] = field.split('.');
    }

    let {label: levelLabel} = Skills[level] || {};
    const {label} = Competencies[competency] || Skills[competency] || {};
    const jprs = get(OFCMinimumStandards, `${field.replace('ofc.', '')}.jprs`, []);

    const handleTrainingClick = useCallback((e, uid) => {
        if (!hasPermission(currentUser, 'training.write')) {
            return;
        }

        const url = `/training/${uid}`;
        window.open(url, '_blank');
    }, [navigate]);

    return (
        <ResponsiveDialog maxWidth="lg" open={open} onClose={onClose}>
            <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <UserAvatar user={member} sx={{mr: 2, width: 50, height: 50}} />
                    <Box sx={{flex: 1}}>
                        {fullName}
                        <Typography variant="subtitle1">{Ranks[role]}</Typography>
                    </Box>
                    <Chip label={`${[levelLabel, label].join(' - ')}`} />
                </Box>
            </DialogTitle>
            <DialogContent dividers sx={{p: 0}}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width={40}></TableCell>
                                <TableCell>JPR</TableCell>
                                <TableCell align="center" width={150}>Last Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jprs.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">No JPRs found</TableCell>
                                </TableRow>
                            )}
                            
                            {jprs.map((jprKey, index) => {
                                const completed = training.find(t => {
                                    const {memberCompetencies = [], jprs = []} = t;

                                    if (memberCompetencies.some(mc => mc.competency === competency && mc[userUid] === true)) {
                                        return true;
                                    }

                                    return jprs.some(tJpr => {
                                        const jprParts = jprKey.split('.');
                                        const tJprParts = tJpr.split('.');
                                        return tJprParts.every((part, index) => part === jprParts[index]);
                                    });
                                });

                                const {uid, date} = completed || {};
                                const standardData = getStandardForKey(jprKey);
                                const {standard, key, title, text: rawText, data = {}, parent} = standardData || {};
                                const {title: standardTitle, edition} = standard || {};
                                const {title: parentTitle} = parent || {};
                                const subsections = Object.keys(data).filter(key => parseInt(key) > 0);

                                const lines = (rawText || '').split('\n');
                                let [text, ...otherLines] = lines;
                                if (text && otherLines.length > 0 && text.startsWith('(')) {
                                    otherLines = [text, ...otherLines].filter(Boolean);
                                    text = '';
                                }

                                const canExpand = subsections.length > 0;
                                const isExpanded = get(expanded, jprKey, false);

                                return (
                                    <TableRow
                                        key={`jpr-${index}-${jprKey}`}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                        <TableCell sx={{pr: 0, color: completed ? lightGreen[900] : null}} align="center" component="th" scope="row">
                                            {completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Box>
                                                {standardTitle && <Typography gutterBottom sx={{color: 'text.secondary'}}>{standardTitle}{edition && ` (${edition})`}</Typography>}
                                                {parentTitle && <Box sx={{fontWeight: 'bold', mb: 1}}>{parentTitle}</Box>}
                                                <span style={{fontWeight: 'bold'}}>{key} {title && `${title}${text ? '.' : ''} `} </span><span>{text}</span>

                                                {otherLines.length > 0 && (
                                                    <Box sx={{ml: 1, mt: 0.5}}>
                                                        {otherLines.map((line, i) => <Box key={`${jprKey}.line.${i}`}>{line}</Box>)}
                                                    </Box>
                                                )}

                                                {canExpand && (
                                                    <Box sx={{mt: 1}}>
                                                        <SmallChip icon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />} label={isExpanded ? 'Show Less' : 'Show More'} onClick={() => setExpanded({...expanded, [jprKey]: !isExpanded})} />
                                                    </Box>
                                                )}

                                                {isExpanded && subsections.map((subsection, index) => <StandardSubsection key={`${jprKey}-${index}`} parent={data} chapter={key} subsection={subsection} />)}
                                            </Box>
                                        </TableCell>
                                        <TableCell component="th" align="center" scope="row">
                                            {date ? (
                                                <Tooltip title={'Click to view training record'}>
                                                    <Chip variant="outlined" label={moment(date).format(dateFormat)} onClick={e => handleTrainingClick(e, uid)} />
                                                </Tooltip>
                                            ) : '-'}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </ResponsiveDialog>
    );
};

export default Training;