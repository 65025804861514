import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import {useSnackbar} from 'notistack';
import {collection, query, where} from 'firebase/firestore';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {httpsCallable} from 'firebase/functions';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {get} from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';

import {useGridApiRef} from '@mui/x-data-grid-pro';

import {db, functions} from '-/firebase';
import {UserContext} from '-/contexts/User';
import {SettingsContext} from '-/contexts/Settings';

import {exportGrid, hasPermission, populateUsers, populateDocsWithUsers, getCollection, populateKeyFromCollection} from '-/data/utils';

import Filter from '-/components/Filter';

import IncidentsGrid from './incidents/Grid';
import Stats from './incidents/Stats';
import Import from './incidents/Import';

const ImportFromIAR = props => {
    const {currentUser, users} = useContext(UserContext);
    const [importing, setImporting] = useState(false);
    const [open, setOpen] = useState(false);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment());
    const [incidents, setIncidents] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const importIncidentsFromIAR = httpsCallable(functions, 'importIncidentsFromIAR');
    const dateFormat = get(currentUser, 'settings.dateFormat') || 'DD/MM/YYYY';

    useEffect(() => {
        if (props.startDate) {
            setStartDate(moment(props.startDate));
        }
    }, [props.startDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImportClose = () => {
        setImportDialogOpen(false);
    };

    const handleImport = useCallback(async() => {
        setImporting(true);

        try {
            const {data} = await importIncidentsFromIAR({
                startDate: startDate.toDate()
            });

            let {incidents = []} = data;
            incidents = await populateKeyFromCollection(db, incidents, 'station', 'stations');
            incidents = await populateKeyFromCollection(db, incidents, 'apparatus', 'apparatus');
            incidents = populateDocsWithUsers(incidents, users);
            setIncidents(incidents);

            setOpen(false);
            setImportDialogOpen(true);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setImporting(false);
    }, [importIncidentsFromIAR, enqueueSnackbar, navigate, startDate]);

    return (
        <>
            <LoadingButton startIcon={<SyncIcon />} loading={importing} variant="outlined" sx={{mr: 1}} onClick={() => setOpen(true)}>
                Import from IaR
            </LoadingButton>

            {incidents && !!incidents.length && <Import incidents={incidents} open={importDialogOpen} onClose={handleImportClose} />}

            <Dialog
                open={open}
                onClose={importing ? null : handleClose}
                fullWidth
                maxWidth="sm"
                disableEscapeKeyDown={importing}
            >
                <DialogTitle>Import from IamResponding</DialogTitle>
                <DialogContent sx={{display: 'flex'}}>
                    <DatePicker
                        sx={{mt: 2, flex: 1}}
                        label={'Start Date'}
                        format={dateFormat}
                        value={startDate}
                        disabled={importing}
                        onChange={date => setStartDate(date && moment(date.toDate()))}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={importing} onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={importing} variant="contained" type="submit" onClick={handleImport}>Start Import</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

const Incidents = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [incidents, setIncidents] = useState([]);
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const {currentUser} = useContext(UserContext);
    const {usesIAR} = useContext(SettingsContext);
    const [latestIncidentDate, setLatestIncidentDate] = useState(null);

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    let defaultDateRange = [moment().startOf('year'), moment().endOf('year')];
    if (startDate && endDate) {
        defaultDateRange = [moment(startDate), moment(endDate)];
    }

    const methods = useForm({
        defaultValues: {
            dateRange: defaultDateRange
        },
        mode: 'onChange'
    });
    const {watch} = methods;
    const dateRange = watch('dateRange');

    // const autosizeOptions = {
    //     // columns: [
    //     //     'date',
    //     //     'incidentNumber',
    //     //     'type',
    //     //     'incidentTypeCode',
    //     //     'dispatchType',
    //     //     'users'
    //     // ],
    //     includeHeaders: true,
    //     includeOutliers: true,
    //     expand: true
    // };

    useEffect(() => {
        if (dateRange) {
            const [startDate, endDate] = dateRange;

            // if startDate and endDate are the default values, we dont need to set search params
            if (startDate.isSame(defaultDateRange[0]) && endDate.isSame(defaultDateRange[1])) {
                return;
            }

            setSearchParams({
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
        }
    }, [dateRange]);

    const fetch = useCallback(async() => {
        setLoading(true);

        try {
            let [startDate, endDate] = dateRange || [];
            startDate = moment(startDate).startOf('day').toDate();
            endDate = moment(endDate).endOf('day').toDate();

            const ref = collection(db, 'incidents');
            const q = query(ref, where('date', '>=', startDate), where('date', '<=', endDate));
            let incidents = await getCollection(db, q);
            incidents = await populateKeyFromCollection(db, incidents, 'station', 'stations');
            incidents = await populateKeyFromCollection(db, incidents, 'apparatus', 'apparatus');
            incidents = await populateUsers(db, incidents);

            const orderedIncidents = incidents.sort((a, b) => {
                return moment(b.date).diff(moment(a.date));
            });

            if (orderedIncidents.length) {
                setLatestIncidentDate(orderedIncidents[0].date);
            }

            setIncidents(incidents);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dateRange]);

    useEffect(() => {
        fetch();
    }, [searchParams]);

    const onRowClick = useCallback((params, e) => {
        const {metaKey} = e;
        const {id, row} = params;

        if (!metaKey) {
            apiRef.current.toggleDetailPanel(id);

            return;
        }
        
        const url = `/incidents/${row.id}`;
        window.open(url);
    }, []);

    const scrollToIncident = useCallback(incident => {
        const {id} = incident;
        
        const el = apiRef.current.getRowElement(id);
        if (el.scrollIntoViewIfNeeded) {
            el.scrollIntoViewIfNeeded();
        }

        apiRef.current.setExpandedDetailPanels([id]);
    }, [apiRef]);

    const handleExport = useCallback(async() => {
        setExporting(true);

        try {
            const data = await exportGrid('incidents', {startDate, endDate});

            const start = moment(startDate).format('YYYY-MM-DD');
            const end = moment(endDate).format('YYYY-MM-DD');

            const href = URL.createObjectURL(data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Incidents - ${start} to ${end}.xlsx`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setExporting(false);
    }, [startDate, endDate, enqueueSnackbar]);

    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <Typography variant="h5" sx={{flex: 1}}>Incidents</Typography>
                
                <Filter loading={loading}>
                    {hasPermission(currentUser, 'incidents.write') && (
                        <>
                            <Button variant="outlined" sx={{mr: 1}} onClick={() => navigate('/incidents/add')} startIcon={<AddIcon />}>Add</Button>
                            {usesIAR && <ImportFromIAR startDate={latestIncidentDate} />}
                        </>
                    )}
                </Filter>
            </Box>

            {(incidents.length > 0) && <Stats incidents={incidents} scrollToIncident={scrollToIncident} expanded={!hasPermission(currentUser, 'incidents.read')} />}

            {hasPermission(currentUser, 'incidents.read') && (
                <Box style={{display: 'flex', flexDirection: 'column'}}>
                    <IncidentsGrid
                        apiRef={apiRef}
                        stateId="incidents"
                        loading={loading}
                        onRowClick={onRowClick}
                        rows={incidents}
                    />
                </Box>
            )}

            {hasPermission(currentUser, 'incidents.read') && (
                <Box sx={{pt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <LoadingButton disabled={loading || !incidents.length} loading={exporting} variant="contained" sx={{ml: 1}} onClick={handleExport}>
                        Export Incidents
                    </LoadingButton>
                </Box>
            )}
        </FormProvider>
    );
};

export default Incidents;