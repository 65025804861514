import React, {useState, useEffect, useCallback} from 'react';
import {useMediaQuery, Box, Typography} from '@mui/material';
import {useSnackbar} from 'notistack';
import {collection, query, where} from 'firebase/firestore';
import {useTheme} from '@mui/material/styles';
import moment from 'moment';
import {useForm, FormProvider} from 'react-hook-form';
import {useSearchParams} from 'react-router-dom';
import {get} from 'lodash';
import {useGridApiRef} from '@mui/x-data-grid-pro';

import {db} from '-/firebase';

import {getCollection, populateKeyFromCollection} from '-/data/utils';

import SearchableDataGrid from '-/components/SearchableDataGrid';
import UserAvatar from '-/components/UserAvatar';

import Filter from '-/components/Filter';

const renderImageCell = params => {
    const {row} = params;

    return (
        <Box sx={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <UserAvatar user={row} />
        </Box>
    );
};

const Payroll = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const apiRef = useGridApiRef();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    let defaultDateRange = [moment().startOf('year'), moment().endOf('year')];
    if (startDate && endDate) {
        defaultDateRange = [moment(startDate), moment(endDate)];
    }

    const methods = useForm({
        defaultValues: {
            dateRange: defaultDateRange
        },
        mode: 'onChange'
    });

    const {watch} = methods;
    const dateRange = watch('dateRange');

    useEffect(() => {
        if (dateRange) {
            const [startDate, endDate] = dateRange;

            // if startDate and endDate are the default values, we dont need to set search params
            if (startDate.isSame(defaultDateRange[0]) && endDate.isSame(defaultDateRange[1])) {
                return;
            }

            setSearchParams({
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
        }
    }, [dateRange]);

    const fetch = useCallback(async() => {
        setLoading(true);

        try {
            let [startDate, endDate] = dateRange || [];
            startDate = moment(startDate).startOf('day').toDate();
            endDate = moment(endDate).endOf('day').toDate();

            const usersRef = collection(db, 'users');
            
            const incidentsRef = collection(db, 'incidents');
            const incidentsQuery = query(incidentsRef, where('date', '>=', startDate), where('date', '<=', endDate));
            const incidents = await getCollection(db, incidentsQuery);

            const trainingRef = collection(db, 'training');
            const trainingQuery = query(trainingRef, where('date', '>=', startDate), where('date', '<=', endDate));
            const training = await getCollection(db, trainingQuery);

            let docs = await getCollection(db, usersRef);
            docs = await populateKeyFromCollection(db, docs, 'station', 'stations');

            docs = docs.map(doc => {
                const {uid} = doc;

                const attendedTraining = training.filter(t => t.members.includes(uid));
                const attendedTrainingHours = attendedTraining.reduce((total, row) => {
                    const {hours, hoursOverrides = {}} = row;
                    const userSalaryOverride = hoursOverrides[uid];

                    if (!hours) {
                        return total;
                    }

                    if (userSalaryOverride) {
                        return total + userSalaryOverride;
                    }

                    return total + parseFloat(hours);
                }, 0);

                const attendedIncidents = incidents.filter(t => (t.users || []).includes(uid));
                const attendedIncidentsHours = attendedIncidents.reduce((total, incident) => {
                    const {durations} = incident;
                    const {salary, salaryOverrides = {}} = durations || {};
                    const userSalaryOverride = salaryOverrides[uid];

                    if (!salary) {
                        return total;
                    }

                    if (userSalaryOverride) {
                        return total + userSalaryOverride;
                    }

                    return total + salary;
                }, 0);

                const hours = {
                    incidents: attendedIncidentsHours,
                    training: attendedTrainingHours
                };

                hours.total = hours.incidents + hours.training;

                return {
                    ...doc,
                    hours
                };
            });

            docs = docs.filter(doc => doc.hours.total > 0);

            setRows(docs);
        } catch(e) {
            enqueueSnackbar(e.message, {variant: 'error'});
        }

        setLoading(false);
    }, [dateRange]);

    useEffect(() => {
        fetch();
    }, [searchParams]);

    const columns = [
        {
            field: 'Image',
            headerName: '',
            disableExport: true,
            align: 'center',
            width: isSmall ? 40 : 60,
            valueGetter: (value, row) => {
                return row && row.imageUrl ? row.imageUrl : '';
            },
            renderCell: renderImageCell,
            sortable: false
        },
        {
            field: 'fullName',
            headerName: 'Name',
            flex: 1,
            minWidth: 200,
            renderCell: params => {
                const {row} = params || {};
                const {fullName, email} = row || {};
                return fullName || email;
            }
        },
        {
            field: 'email',
            minWidth: 150,
            headerName: 'Email',
            sortable: false
        },
        {
            field: 'employeeId',
            headerName: 'Employee ID',
            minWidth: 130,
            valueFormatter: value => {
                return (value || '').toUpperCase();
            }
        },
        {
            field: 'station',
            headerName: 'Station',
            minWidth: 100,
            valueGetter: value => {
                const {name} = value || {};
                return name || '-';
            }
        },
        {
            field: 'hours.incidents',
            headerName: 'Incidents',
            minWidth: 120,
            type: 'number',
            total: 'sum',
            valueGetter: (value, row) => {
                return get(row, 'hours.incidents', 0);
            }
        },
        {
            field: 'hours.trainings',
            headerName: 'Training',
            minWidth: 120,
            type: 'number',
            total: 'sum',
            valueGetter: (value, row) => {
                return get(row, 'hours.training', 0);
            }
        },
        {
            field: 'hours.total',
            headerName: 'Total Hours',
            minWidth: 140,
            type: 'number',
            total: 'sum',
            valueGetter: (value, row) => {
                return get(row, 'hours.total', 0);
            }
        }
    ];

    return (
        <FormProvider {...methods}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <Typography variant="h5" sx={{flex: 1, mr: 1}}>Payroll</Typography>
            
                <Filter loading={loading} />
            </Box>
            
            <SearchableDataGrid
                apiRef={apiRef}
                stateId="payroll"
                rows={rows}
                loading={loading}
                columns={columns}
                showTotals
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            email: false,
                            station: false,
                            employeeId: false
                        }
                    }
                }}
                
            />
        </FormProvider>
    );
};

export default Payroll;