import React, {useEffect, useState, useMemo, useCallback} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {capitalize} from 'lodash';
import {debounce} from '@mui/material/utils';
import {useController} from 'react-hook-form';

const autocompleteService = {current: null};
const geocoderService = {current: null};

export default function GoogleAutocompleteField(props) {
    const {name, label, required, rules: rawRules, helperText} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const {field, fieldState: {error}} = useController({name, rules});
    const {value, onChange} = field;

    const fetch = useMemo(() => {
        return debounce((request, callback) => {
            autocompleteService.current.getPlacePredictions(request, callback);
        }, 400);
    }, []);

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        if (value) {
            setOptions([value]);
            return;
        }

        fetch({input: inputValue, region: 'ca'}, results => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, value, fetch]);

    const handleChange = useCallback((event, newValue) => {
        // setOptions(newValue ? [newValue, ...options] : options);

        if (!newValue) {
            onChange(null);
            return;
        }

        if (!geocoderService.current && window.google) {
            geocoderService.current = new window.google.maps.Geocoder();
        }

        if (!geocoderService.current) {
            return undefined;
        }

        if (!newValue) {
            return;
        }

        const {description, place_id: placeId} = newValue;

        geocoderService.current.geocode({placeId}, (responses, status) => {
            if (status === 'OK') {
                const {formatted_address: formattedAddress, address_components: addressComponents, geometry} = responses[0];

                const zipcode = addressComponents.find(component => component.types.includes('postal_code'))?.long_name;
                const country = addressComponents.find(component => component.types.includes('country'))?.long_name;
                const countryCode = addressComponents.find(component => component.types.includes('country'))?.short_name;
                const provider = 'google';
                const latitude = geometry.location.lat();
                const longitude = geometry.location.lng();

                onChange({
                    description,
                    formattedAddress,
                    country,
                    countryCode,
                    zipcode,
                    latitude,
                    longitude,
                    provider
                });
            }
        });
    }, [options]);

    return (
        <Autocomplete
            value={value || null}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            getOptionLabel={option => option.description || option.formattedAddress}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    error={!!error}
                    helperText={error && helperText !== false ? error.message : helperText}
                />
            )}
        />
    );
    // <Autocomplete
    //     getOptionLabel={option =>
    //         typeof option === 'string' ? option : option.description
    //     }
    //     filterOptions={x => x}
    //     options={options}
    //     autoComplete
    //     includeInputInList
    //     filterSelectedOptions
    //     value={value}
    //     noOptionsText="No locations"
    //     onChange={handleChange}
    //     onInputChange={(event, newInputValue) => {
    //         setInputValue(newInputValue);
    //     }}
    //     renderInput={params => (
    //         <TextField {...params} label="Add a location" fullWidth />
    //     )}
    //     renderOption={(props, option) => {
    //         const matches = option.structured_formatting.main_text_matched_substrings || [];

    //         const parts = parse(
    //             option.structured_formatting.main_text,
    //             matches.map(match => [match.offset, match.offset + match.length])
    //         );

    //         return (
    //             <li {...props}>
    //                 <Grid container alignItems="center">
    //                     <Grid item sx={{display: 'flex', width: 44}}>
    //                         <LocationOnIcon sx={{color: 'text.secondary'}} />
    //                     </Grid>
    //                     <Grid item sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
    //                         {parts.map((part, index) => (
    //                             <Box
    //                                 key={index}
    //                                 component="span"
    //                                 sx={{fontWeight: part.highlight ? 'bold' : 'regular'}}
    //                             >
    //                                 {part.text}
    //                             </Box>
    //                         ))}
    //                         <Typography variant="body2" color="text.secondary">
    //                             {option.structured_formatting.secondary_text}
    //                         </Typography>
    //                     </Grid>
    //                 </Grid>
    //             </li>
    //         );
    //     }}
    //     {...rest}
    // />
    // );
}