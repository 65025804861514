import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Stack, Box, Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';

import TextField from '-/form/TextField';
import DatePickerField from '-/form/DatePickerField';
import CollectionAutocompleteField from '-/form/CollectionAutocompleteField';
import ImagesUploadField from '-/form/ImagesUploadField';
import UserAutocompleteField from '-/form/UserAutocompleteField';

import ResponsiveDialog from '-/components/ResponsiveDialog';

export default function ItemDialog({onSubmit: onUpdate, onDelete, parent, item, open, handleClose}) {
    const [loading, setLoading] = useState(false);
    const methods = useForm({
        defaultValues: useMemo(() => {
            const {additionalFields = []} = parent || {};

            return item || {
                ...additionalFields.reduce((result, field) => {
                    const {id} = field;

                    return {
                        ...result,
                        [id]: ''
                    };
                }, {})
            };
        }, [item, parent]),
        mode: 'onChange'
    });
    const {reset, handleSubmit} = methods;

    const {additionalFields = []} = parent || {};

    useEffect(() => {
        reset(item);
    }, [reset, item]);

    const handleDelete = async () => {
        setLoading();

        const result = await onDelete(item);
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    };

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const result = await onUpdate(data);
        
        if (result) {
            handleClose();
            reset({});
        }

        setLoading(false);
    }, [handleClose, onUpdate, reset]);

    return (
        <FormProvider {...methods}>
            <ResponsiveDialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    noValidate: true,
                    onSubmit: handleSubmit(onSubmit)
                }}
            >
                <DialogTitle>{item ? 'Update Item' : 'Add New Item'}</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <ImagesUploadField multiple name="image" />

                        {additionalFields.map((field, index) => {
                            const {id, label, type} = field;
                            const key = `additional-field-${index}`;

                            const base = {
                                name: id,
                                label,
                                fullWidth: true,
                                disabled: loading
                            };

                            if (type === 'date') {
                                return (
                                    <DatePickerField
                                        key={key}
                                        {...base}
                                    />
                                );
                            } else if (type === 'apparatus') {
                                return (
                                    <CollectionAutocompleteField
                                        key={key}
                                        {...base}
                                        collection="apparatus"
                                        displayProperty="tag"
                                        multiple={false}
                                    />
                                );
                            } else if (type === 'user') {
                                return (
                                    <UserAutocompleteField
                                        key={key}
                                        {...base}
                                        multiple={false}
                                    />
                                );
                            }

                            return (
                                <TextField
                                    key={key}
                                    {...base}
                                />
                            );
                        })}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    {(item && onDelete) && (
                        <>
                            <Button onClick={handleDelete} disabled={loading}>Delete Item</Button>
                            <Box sx={{flex: 1}} />
                        </>
                    )}
                    <Button onClick={handleClose} disabled={loading}>Cancel</Button>
                    <LoadingButton loading={loading} variant="contained" type="submit" disabled={loading}>{item ? 'Update' : 'Add'} Item</LoadingButton>
                </DialogActions>
            </ResponsiveDialog>
        </FormProvider>
    );
};