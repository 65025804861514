import React, {useState, useCallback, useEffect} from 'react';
import {Alert, Tooltip, Button, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {LoadingButton} from '@mui/lab';

import ResponsiveDialog from '-/components/ResponsiveDialog';

import SearchableDataGrid from '-/components/SearchableDataGrid';

const ImportButton = ({selectionCount, ...rest}) => {
    return (
        <LoadingButton variant="contained" {...rest}>
            Import Selected ({selectionCount})
        </LoadingButton>
    );
};

export default function Import(props) {
    const {responses: rawResponses = [], onImport, onClose, open} = props;

    const [responses, setResponses] = useState(rawResponses || []);
    const {enqueueSnackbar} = useSnackbar();
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const navigate = useNavigate();
    const hasResponsesWithoutUser = responses.some(response => !response.user);

    useEffect(() => {
        setResponses(rawResponses || []);
        setRowSelectionModel(rawResponses.reduce((result, response) => {
            const {id, user} = response;
            if (user) {
                result.push(id);
            }

            return result;
        }, []));
    }, [rawResponses]);

    const handleImport = useCallback(async () => {
        const toImport = responses.filter(item => {
            const {id} = item;
            return rowSelectionModel.includes(id);
        });

        if (!toImport.length) {
            enqueueSnackbar('No responses selected', {variant: 'error'});
            return;
        }

        onImport(toImport.filter(item => item.user));
    }, [enqueueSnackbar, responses, rowSelectionModel, navigate]);

    return (
        <ResponsiveDialog maxWidth="xl" open={open} onClose={onClose}>
            <DialogTitle sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
                Import Responses from IaR
            </DialogTitle>
            <DialogContent dividers>
                {hasResponsesWithoutUser && (
                    <Alert severity="warning" sx={{mb: 2}}>
                        Some responses do not have a corresponding user. These will not be imported.
                    </Alert>
                )}

                <SearchableDataGrid
                    rows={responses}
                    checkboxSelection
                    onRowSelectionModelChange={newRowSelectionModel => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    isRowSelectable={params => params.row.user}
                    getRowClassName={params => {
                        const {row} = params;
                        const {user} = row || {};
                        const classNames = [];

                        if (!user) {
                            classNames.push('has-issues');
                        }

                        return classNames.join(' ');
                    }}
                    sx={{
                        '& .has-issues, &:hover .has-issues': {
                            backgroundColor: 'rgba(250, 0, 0, .05)'
                        }
                    }}
                    columns={[
                        {
                            field: 'user',
                            headerName: 'Name',
                            flex: 1,
                            renderCell: params => {
                                const {row} = params || {};
                                const {user, raw} = row || {};
                                const {fullName} = user || {};

                                if (fullName) {
                                    return fullName;
                                }

                                const rawName = [raw.firstName, raw.lastName].filter(Boolean).join(' ');
                                return (
                                    <Tooltip title="No user found" arrow>
                                        <i>{rawName}</i>
                                    </Tooltip>
                                );
                            }
                        },
                        {
                            field: 'response',
                            headerName: 'Response',
                            minWidth: 200
                        }
                    ]}
                    initialState={{
                        sorting: {
                            sortModel: [
                                {field: 'user', sort: 'desc'},
                                {field: 'response', sort: 'asc'}
                            ]
                        }
                    }}
                    onRowClick={null}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ImportButton onClick={handleImport} selectionCount={rowSelectionModel.length} />
            </DialogActions>
        </ResponsiveDialog>
    );
}
