import React from 'react';
import {Box, Card, CardContent, Chip, Stack, Typography} from '@mui/material';

const StatCard = props => {
    const {title, heading, legend, overlap = false, trend = false, interval, children, sx} = props;

    const labelColors = {
        up: 'success',
        down: 'error',
        neutral: 'default'
    };

    const color = labelColors[trend || 'neutral'];
    const trendValues = {up: '+25%', down: '-25%', neutral: '+5%'};

    return (
        <Card variant="outlined" sx={{...sx}}>
            <CardContent sx={{position: 'relative'}}>
                <Box sx={overlap ? {position: 'absolute', top: 16, left: 16} : {}}>
                    {(title || trend || legend) && (
                        <Stack
                            direction="row"
                            sx={{justifyContent: 'space-between', alignItems: 'center'}}
                        >
                            <Typography component="h4" variant="subtitle2" sx={{opacity: 0.6}}>
                                {title}
                            </Typography>
                            {(trend || legend) && <Chip size="small" color={color} label={trendValues[trend] || legend} />}
                        </Stack>
                    )}
                    {(heading || interval) && (
                        <Stack sx={{justifyContent: 'space-between'}}>
                            <Typography variant="h3" component="p">
                                {heading}
                            </Typography>
                            <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                {interval}
                            </Typography>
                        </Stack>
                    )}
                </Box>
                <Box sx={{width: '100%'}}>
                    {children}
                </Box>
            </CardContent>
        </Card>
    );
};

export default StatCard;