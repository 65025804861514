import React, {useState, useContext, useEffect} from 'react';
import {doc, query, collection, where, onSnapshot} from 'firebase/firestore';

import {db} from '-/firebase.js';
import {UserContext} from '-/contexts/User';

import {getCollection, ensureMomentDates, populateUsers, populateKeyFromCollection} from '-/data/utils';

import TrainingGrid from '-/pages/training/Grid';

export default function User(props) {
    const {user: rawUser} = props;

    // const [user, setUser] = useState(rawUser || {});
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const {currentUser} = useContext(UserContext);

    const handleFetch = async raw => {
        let userData = ensureMomentDates({
            id: raw.id,
            uid: raw.id,
            ...raw.data()
        });

        setLoading(false);
        // setUser(userData);

        if (isSubscribed) {
            const q = query(collection(db, 'training'), where('members', 'array-contains', userData.id));

            let training = await getCollection(db, q);
            training = await populateUsers(db, training);
            training = await populateKeyFromCollection(db, training, 'types', 'trainingTypes');

            setRows(training);
        }
    };
    
    let isSubscribed = true;

    useEffect(() => {
        const {uid} = rawUser || {};
        if (!uid) {
            return;
        }

        const ref = doc(db, 'users', uid);
        const snapshot = onSnapshot(ref, handleFetch);
        
        return () => {
            snapshot();
            isSubscribed = false;
        };
    }, [rawUser, currentUser]);

    // const groupedByYear = rows.reduce((acc, row) => {
    //     const {date} = row;
    //     const year = date.format('YYYY');
    //     acc[year] = acc[year] || [];
    //     acc[year].push(row);
    //     return acc;
    // }, {});

    return (
        <>
            {/* <Grid container spacing={1} sx={{mb: 2}}>
                <Grid size={{xs: 12, sm: 6, md: 4}}>
                    {Object.keys(groupedByYear).map(year => {
                        const rows = groupedByYear[year];

                        return (
                            <StatCard
                                key={year}
                                heading={rows.length}
                                title={year}
                                interval={'Last days'}
                                overlap
                                sx={{height: 100}}
                            />
                        );
                    })}
                </Grid>
            </Grid> */}

            <TrainingGrid
                training={rows}
                loading={loading}
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: 'date', sort: 'desc'}
                        ]
                    },
                    columns: {
                        columnVisibilityModel: {
                            type: false,
                            instructors: false,
                            hours: false,
                            members: false
                        }
                    }
                }}
            />
        </>
    );
}