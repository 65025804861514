import React, {forwardRef} from 'react';
import {DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';
import {Box, Stack, useTheme, useMediaQuery} from '@mui/material';
import {get} from 'lodash';
import {unflatten} from 'flat';

import StatefulDataGrid from './StatefulDataGrid';

function Toolbar(props) {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const {disableColumnFilter} = props;

    return (
        <GridToolbarContainer>
            {!isSmall && (
                <Stack direction="row" spacing={1} sx={{flex: 1}}>
                    <GridToolbarColumnsButton />
                    <GridToolbarExport />
                </Stack>
            )}

            {!disableColumnFilter && <GridToolbarQuickFilter variant="outlined" size="small" sx={{...isSmall && {flex: 1}}} />}
        </GridToolbarContainer>
    );
}

export default forwardRef((props, ref) => {
    const {stateId, columns = [], rows = [], showTotals, loading, disableToolbar, hasConatiner = true, ...rest} = props;
    const {disableColumnFilter} = rest;
    const Component = stateId ? StatefulDataGrid : DataGridPro;
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    let allRows = rows;
    if (showTotals) {
        allRows = [...rows];
        if (showTotals) {
            const totalRow = columns.reduce((result, column) => {
                const {field, total: config} = column;

                if (config) {
                    if (config === 'sum') {
                        result[field] = allRows.reduce((total, row) => total + get(row, field, 0), 0);
                    } else if (config === 'average') {
                        result[field] = allRows.reduce((total, row) => total + get(row, field, 0), 0) / allRows.length;
                    } else {
                        result[field] = '';
                    }
                }

                return result;
            }, {});

            allRows.push({
                id: 'total',
                ...unflatten(totalRow)
            });
        }
    }

    const content = (
        <Component
            ref={ref}
            stateId={stateId}
            disableColumnFilter
            disableDensitySelector
            slots={{toolbar: !disableToolbar && Toolbar}}
            slotProps={{
                toolbar: {disableColumnFilter}
            }}
            getRowClassName={row => (row.id === 'total' ? 'total-row' : '')}
            rows={allRows}
            loading={loading}
            columns={columns.map(column => {
                return {
                    disableColumnMenu: isSmall,
                    ...column
                };
            })}
            {...rest}
        />
    );

    if (!hasConatiner) {
        return content;
    }

    return (
        <Box sx={{width: '100%'}}>
            <Box style={{display: 'flex', flexDirection: 'column', minHeight: !allRows || allRows.length === 0 || loading ? 250 : 'auto'}}>
                {content}
            </Box>
        </Box>
    );
});