import React from 'react';
import {useController} from 'react-hook-form';
import {Autocomplete, TextField} from '@mui/material';
import {capitalize} from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export default function ControlledAutocompleteField(props) {
    const {name, label, required, rules: rawRules, helperText, options, multiple = true, variant, objectValue = true, ...rest} = props;
    const rules = {...rawRules};
    const fieldLabel = label !== false ? label || capitalize(name) : null;

    if (required && !rules.required) {
        rules.required = fieldLabel ? `${fieldLabel} is required` : 'This field is required';
    }

    const {field, fieldState: {error}} = useController({name, rules});
    const {value: rawValue, onChange, ...restField} = field;
    const value = objectValue ? rawValue : options.find(option => option.value === rawValue);

    return (
        <Autocomplete
            value={value || (multiple ? [] : '')}
            options={options}
            onChange={(e, newValue) => {
                onChange(objectValue ? newValue : newValue?.value);
            }}
            getOptionLabel={option => option.label || ''}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            filterSelectedOptions
            renderInput={params => (
                <TextField
                    name={name}
                    {...params}
                    {...variant && {variant}}
                    {...required && {required}}
                    {...label && {label}}
                    error={!!error}
                    helperText={error && helperText !== false ? error.message : helperText}
                />
            )}
            renderOption={(props, option, {inputValue}) => {
                const {key, ...optionProps} = props;
                const matches = match(option.label, inputValue, {insideWords: true});
                const parts = parse(option.label, matches);
        
                return (
                    <li key={key} {...optionProps}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            multiple={multiple}
            {...rest}
            {...restField}
        />
    );
};
