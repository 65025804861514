import React, {useState, useEffect, useCallback} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import {BrowserRouter} from 'react-router-dom';
import {onSnapshot, collection, doc} from 'firebase/firestore';
import {onAuthStateChanged} from 'firebase/auth';
import {SnackbarProvider} from 'notistack';
import {LicenseInfo} from '@mui/x-license';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers-pro/AdapterMoment';
import {useJsApiLoader} from '@react-google-maps/api';
import {sortBy} from 'lodash';
import {setUserId} from 'firebase/analytics';
import {ConfirmProvider} from 'material-ui-confirm';

import {UserContext} from '-/contexts/User';
import {SettingsContext} from '-/contexts/Settings';
import {StationsContext} from '-/contexts/Stations';
import {db, auth, analytics} from '-/firebase.js';

import Theme from '-/style/Theme';

import AppContainer from '-/components/AppContainer';
import Router from '-/Router';

import {processRawDoc, processRawDocs, processUserName} from '-/data/utils';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

LicenseInfo.setLicenseKey('d0b27aac11bcb2423551b1b366d732a5Tz04NjMyMCxFPTE3NDE4ODQ5NzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const libraries = ['places'];

export default function App() {
    const [initializingAuth, setInitializingAuth] = useState(true);
    const [initializingSettings, setInitializingSettings] = useState(true);
    const [initializingStations, setInitializingStations] = useState(true);
    const [currentUser, setCurrentUser] = useState();
    const [member, setMember] = useState();
    const [users, setUsers] = useState([]);
    const [settings, setSettings] = useState(null);
    const [stations, setStations] = useState(null);

    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCCI_7230YkA7R8LJXiqrg6_zVlfy9eSfk',
        libraries
    });

    const fetchSettings = useCallback(() => {
        const ref = doc(db, 'settings', 'general');
        const unsubscribe = onSnapshot(ref, snapshot => {
            const row = processRawDoc(snapshot);
            setSettings(row || {});
            setInitializingSettings(false);
        });
        return unsubscribe;
    }, [db]);

    const fetchStations = useCallback(() => {
        const ref = collection(db, 'stations');
        const unsubscribe = onSnapshot(ref, snapshot => {
            const stationsData = processRawDocs(snapshot);
            setStations(sortBy(stationsData, 'name'));
            setInitializingStations(false);
        });
        return unsubscribe;
    }, [db]);

    useEffect(() => {
        const unsubscribeSettings = fetchSettings();
        const unsubscribeStations = fetchStations();
        
        return () => {
            unsubscribeSettings();
            unsubscribeStations();
        };
    }, [fetchSettings, fetchStations]);

    const handleAuthStateChange = useCallback(async user => {
        if (!stations) {
            return;
        }

        if (user) {
            const {currentUser} = auth;
            const {uid} = currentUser || {};
            const ref = collection(db, 'users');

            const {claims} = await currentUser.getIdTokenResult();
            const {permissions = {}} = claims || {};
            
            const unsubscribeUsers = onSnapshot(ref, async snapshot => {
                let newUsers = processRawDocs(snapshot);
                // TODO needs to be automated
                newUsers = newUsers.map(user => processUserName(user, {users: newUsers}));
                
                let currentUser = newUsers.find(doc => doc.uid === uid);
                if (!currentUser) {
                    const station = stations.find(station => station.userUid === uid);

                    if (station) {
                        currentUser = {
                            ...station,
                            uid,
                            fullName: `Station ${station.name}`,
                            station: station.uid,
                            isStation: true
                        };
                    }
                }

                if (currentUser && currentUser.uid) {
                    setUserId(analytics, currentUser.uid);
                }

                setCurrentUser(prev => (prev?.uid === currentUser?.uid ? prev : {
                    ...currentUser,
                    permissions
                }));

                setUsers(newUsers);
                setInitializingAuth(false);
            });

            return () => unsubscribeUsers();
        } else {
            setCurrentUser(undefined);
            setUsers([]);
            setInitializingAuth(false);
        }
    }, [stations]);

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, handleAuthStateChange);

        return () => {
            unsubscribeAuth();
        };
    }, [auth, db, initializingStations, stations]);

    const initializing = initializingAuth || initializingSettings || initializingStations;
    if (initializing) {
        return (
            <Backdrop invisible open>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    const confirmProviderDefaultOptions = {
        confirmationButtonProps: {
            variant: 'contained',
            color: 'error'
        },
        contentProps: {
            sx: {
                pt: 2
            }
        }
    };

    return (
        <SettingsContext.Provider value={settings || {}}>
            <UserContext.Provider value={{currentUser, member, setMember, users}}>
                <StationsContext.Provider value={{stations}}>
                    <Theme>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-ca">
                            <ConfirmProvider defaultOptions={confirmProviderDefaultOptions}>
                                <SnackbarProvider maxSnack={1} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                                    <BrowserRouter>
                                        <AppContainer>
                                            <Router />
                                        </AppContainer>
                                    </BrowserRouter>
                                </SnackbarProvider>
                            </ConfirmProvider>
                        </LocalizationProvider>
                    </Theme>
                </StationsContext.Provider>
            </UserContext.Provider>
        </SettingsContext.Provider>
    );
}
