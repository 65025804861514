import React, {useEffect, useState, useCallback} from 'react';
import {Grid2 as Grid, Box, Card, CardContent, FormControlLabel, Stack, Divider, Typography} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {doc, setDoc} from 'firebase/firestore';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from 'notistack';

import {db} from '-/firebase';

import {getCollectionDoc, uploadImage, ensureJSDates, ensureMomentDates} from '-/data/utils';

import ImageUploadField from '-/form/ImageUploadField';
import TextField from '-/form/TextField';
import PasswordField from '-/form/PasswordField';
import CheckboxField from '-/form/CheckboxField';

const FormRow = ({label, helperText, children}) => {
    return (
        <FormControlLabel
            labelPlacement="start"
            slotProps={{
                typography: {
                    mr: 2,
                    flex: 1,
                    textAlign: 'right'
                }
            }}
            control={children}
            label={(
                <Stack>
                    <Typography variant="body1" sx={{flex: 1}}>
                        {label}
                    </Typography>
                    {helperText && <Typography variant="caption" sx={{fontSize: '0.7rem', lineHeight: 1.5}} color="text.secondary">{helperText}</Typography>}
                </Stack>
            )}
        />
    );
};

export default function() {
    const [loading, setLoading] = useState(true);
    const {enqueueSnackbar} = useSnackbar();

    const methods = useForm({
        defaultValues: {
            siteName: '',
            limitToDomain: false,
            domain: '',
            displayCarsToStationLogin: true,
            allowStationLogin: false,
            allowRegistrationWithoutUser: false,
            usesJIBC: false,
            usesIAR: false,
            sendPretripEmail: false,

            secret: {
                iar: {
                    agency: '',
                    username: '',
                    password: ''
                }
            }
        },
        mode: 'onChange'
    });

    const {reset, handleSubmit, watch} = methods;
    const limitToDomain = watch('limitToDomain');
    const usesIAR = watch('usesIAR');
    const sendPretripEmail = watch('sendPretripEmail');
    const allowStationLogin = watch('allowStationLogin');

    useEffect(() => {
        const fetch = async () => {
            try {
                const general = await getCollectionDoc(db, 'settings', 'general');
                const secret = await getCollectionDoc(db, 'settings', 'secret');
                
                reset(ensureMomentDates({
                    ...general,
                    secret
                }));
            } catch(e) {
                enqueueSnackbar(e, {variant: 'error'});
            }

            setLoading(false);
        };

        fetch();
    }, []);

    const onSubmit = useCallback(async data => {
        setLoading(true);

        const {imageFile, secret: secretData, ...rest} = data;

        try {
            const generalRef = doc(db, 'settings', 'general');
            const secretRef = doc(db, 'settings', 'secret');

            const data = {
                ...rest
            };

            if (imageFile) {
                data.image = await uploadImage('settings/general', imageFile);
            } else if (imageFile === null) {
                data.image = null;
            }

            await setDoc(generalRef, ensureJSDates(data));

            if (secretData) {
                await setDoc(secretRef, ensureJSDates(secretData));
            }

            enqueueSnackbar('Settings saved', {variant: 'success'});
        } catch(e) {
            enqueueSnackbar(e, {variant: 'error'});
        }

        setLoading(false);
    }, []);

    return (
        <FormProvider {...methods}>
            <Typography variant="h5" gutterBottom>Settings</Typography>

            <Divider sx={{mb: 2}} />

            <Stack spacing={1}>
                <FormRow label="Logo">
                    <Box sx={{flex: 2}}>
                        <ImageUploadField disabled={loading} sx={{width: 200}} name="image" label="Logo" />
                    </Box>
                </FormRow>

                <FormRow label="Site Name">
                    <TextField disabled={loading} name="siteName" sx={{flex: 2}} label={false} />
                </FormRow>

                <Divider />

                <FormRow label="Limit to Domain" helperText="If checked, only users with email addresses from the domain will be able to register">
                    <Box sx={{flex: 2}}>
                        <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="limitToDomain" />
                    </Box>
                </FormRow>

                {limitToDomain && (
                    <Stack direction="row">
                        <Box sx={{flex: 1}} />
                        <Card sx={{flex: 2, ml: 2}}>
                            <CardContent>
                                <TextField
                                    disabled={loading}
                                    name="domain"
                                    rules={{
                                        required: 'Domain is required if limiting to domain',
                                        pattern: {
                                            value: /^[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid domain'
                                        }
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Stack>
                )}

                <Divider />

                <FormRow label="Allow Station Login" helperText="Allows users to login as a station, with restricted permissions">
                    <Box sx={{flex: 2}}>
                        <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="allowStationLogin" />
                    </Box>
                </FormRow>

                {allowStationLogin && (
                    <>
                        <FormRow label="Station Login Password" helperText="The password required to login as a station">
                            <PasswordField rules={{required: 'A station password is required'}} disabled={loading} name="secret.stationLoginPassword" sx={{flex: 2}} label={false} />
                        </FormRow>

                        <FormRow label="Display Cars to Station Users" helperText="Displays apparatus marked as Cars to station login users">
                            <Box sx={{flex: 2}}>
                                <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="displayCarsToStationLogin" />
                            </Box>
                        </FormRow>

                    </>
                )}

                <Divider />

                <FormRow label="Allow Registration without User" helperText="Allows users to register, even when a user has not been previously added">
                    <Box sx={{flex: 2}}>
                        <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="allowRegistrationWithoutUser" />
                    </Box>
                </FormRow>

                <Divider />

                <FormRow label="Use JIBC" helperText="If checked, allows upload of JIBC transcripts as well as saving JIBC data for the user">
                    <Box sx={{flex: 2}}>
                        <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="usesJIBC" />
                    </Box>
                </FormRow>

                <Divider />

                <FormRow label="Send Pretrip/Posttrip PDF to Email" helperText="Sends a PDF of the completed pretrip/posttrip to the specified email(s)">
                    <Box sx={{flex: 2}}>
                        <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="sendPretripEmail" />
                    </Box>
                </FormRow>

                {sendPretripEmail && (
                    <Stack direction="row">
                        <Box sx={{flex: 1}} />
                        <Card sx={{flex: 2, ml: 2}}>
                            <CardContent>
                                <TextField
                                    disabled={loading}
                                    label="Email(s)"
                                    name="sendPretripEmailTo"
                                    placeholder="Comma separated list of emails"
                                    fullWidth
                                    rules={{
                                        required: 'An email is required if sending pretrip/posttrip PDFs',
                                        pattern: {
                                            value: /^(\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*,)*\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
                                            message: 'Invalid email'
                                        }
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Stack>
                )}

                <Divider />

                <FormRow label="Use IamResponding" helperText="Allows importing of incidents and training from IamResponding">
                    <Box sx={{flex: 2}}>
                        <CheckboxField disabled={loading} sx={{ml: 0}} label={false} name="usesIAR" />
                    </Box>
                </FormRow>

                {usesIAR && (
                    <Stack direction="row">
                        <Box sx={{flex: 1}} />
                        <Card sx={{flex: 2, ml: 2}}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <TextField disabled={loading} name="secret.iar.agency" label="IaR Agency" fullWidth />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <TextField disabled={loading} name="secret.iar.username" label="IaR Username" fullWidth />
                                    </Grid>
                                    <Grid size={{xs: 12, sm: 6, md: 4}}>
                                        <PasswordField disabled={loading} name="secret.iar.password" label="IaR Password" fullWidth />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Stack>
                )}

                <Stack direction="row" sx={{justifyContent: 'flex-end'}}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save Settings
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
};