import React from 'react';

import CRUDDataGrid from '-/components/CRUDDataGrid';

const Stations = function() {
    const columns = [
        {field: 'name', headerName: 'Number', flex: 1, editable: true}
    ];

    return (
        <CRUDDataGrid
            collection="stations"
            label="Station"
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'name', sort: 'asc'}
                    ]
                }
            }}
            columns={columns}
        />
    );
};

export default Stations;