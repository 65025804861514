import React, {useContext} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';

import {UserContext} from '-/contexts/User';
import {hasFeature} from '-/features.js';

import {hasPermission} from '-/data/utils';

import Login from '-/pages/Login';
import NotFound from '-/pages/NotFound';
import Manage from '-/pages/Manage';

import Stations from '-/pages/Stations';
import Settings from '-/pages/Settings';

import Dashboard from '-/pages/Dashboard';

import Incidents from '-/pages/Incidents';
import Incident from '-/pages/Incident';
import ImportIncidents from '-/pages/incidents/Import';

import Members from '-/pages/Members';
import Member from '-/pages/member';
import QualificationMatrix from '-/pages/QualificationMatrix';
import TrainingMatrix from '-/pages/TrainingMatrix';

import Apparatuses from '-/pages/Apparatuses';
import Apparatus from '-/pages/Apparatus';
import EditApparatus from '-/pages/apparatuses/Edit';

import EditChecks from '-/pages/apparatuses/EditChecks';
import EditFullChecks from '-/pages/apparatuses/EditFullChecks';
import Pretrip from '-/pages/apparatuses/Pretrip';
import Check from '-/pages/apparatuses/Check';
import FullCheck from '-/pages/apparatuses/FullCheck';

import Calendar from '-/pages/Calendar';

import DriverLogs from '-/pages/DriverLogs';

import Payroll from '-/pages/Payroll';

import Tasks from '-/pages/Tasks';

import Trainings from '-/pages/Trainings';
import Training from '-/pages/Training';
import ImportTraining from '-/pages/training/Import';
import TrainingKeywords from '-/pages/training/Keywords';

import SkillSheet from '-/pages/SkillSheet';

// import Gear from '-/pages/Gear';
// import Sheet from '-/pages/Sheet';

import Respond from '-/pages/respond';
import RespondMap from '-/pages/respond/Map';

import Activities from '-/pages/vs/Activities';

import NFPA from '-/pages/NFPA';

import Gear from '-/pages/Gear';
import GearCollection from '-/pages/GearCollection';
import GearItem from '-/pages/GearItem';

export default function() {
    const {currentUser} = useContext(UserContext);
    const {isStation} = currentUser || {};

    const reRouteIfAuthenticated = destination => {
        if (currentUser) {
            return <Navigate to="/" />;
        }

        return destination;
    };

    const reRouteIfUnauthenticated = destination => {
        if (!currentUser) {
            return <Navigate to="/login" />;
        }

        return destination;
    };

    const generateGearRoutes = (maxDepth = 10) => {
        const routes = [
            {path: 'gear/:id', element: 'collection'}
        ];

        let path = 'gear/:collectionId';

        for (let depth = 1; depth <= maxDepth; depth++) {
            if (depth === 1) {
                // First level: gear/:collectionId/:id with GearItem component
                routes.push({path: `${path}/:id`, element: 'item'});
            } else if (depth % 2 === 0) {
                // Even depth: gear/.../gear/:id with GearCollection component
                path += '/gear';
                routes.push({path: `${path}/:id`, element: 'collection'});
            } else {
                // Odd depth: gear/.../:subCollectionId/:id with GearItem component
                path += `/:subCollectionId${depth - 2}`;
                routes.push({path: `${path}/:id`, element: 'item'});
            }
        }

        // Convert route objects to <Route /> components
        return routes.map(route => (
            <Route key={route.path} path={route.path} element={route.element === 'collection' ? <GearCollection /> : <GearItem />} />
        ));
    };

    return (
        <Routes>
            <Route path="/login" element={reRouteIfAuthenticated(<Login />)} />
            <Route path="/manage" element={reRouteIfAuthenticated(<Manage />)} />
            <Route path="*" element={reRouteIfUnauthenticated(<NotFound />)} />

            {currentUser && (
                <Route path="*">
                    <Route index element={<Dashboard />} />

                    {!isStation && (
                        <>
                            <Route path="profile" element={<Member isProfile />} />
                            <Route path="profile/qualifications" element={<Member isProfile activeTab="qualifications" />} />
                            <Route path="profile/statistics" element={<Member isProfile activeTab="statistics" />} />
                            <Route path="profile/transcript" element={<Member isProfile activeTab="transcript" />} />
                        </>
                    )}

                    {hasPermission(currentUser, 'users.write') && (
                        <Route path="profile/jibcConsent" element={<Member isProfile activeTab="jibcConsent" />} />
                    )}

                    {hasPermission(currentUser, 'apparatus.read') && (
                        <Route path="apparatus">
                            <Route index element={<Apparatuses />} />
                            <Route path="add" element={<EditApparatus />} />

                            <Route path=":id">
                                <Route index element={<Apparatus />} />
                                <Route path="edit" element={<EditApparatus />} />
                                <Route path=":type" element={<Navigate to=".." replace />} />

                                <Route path="pretrips/:checkId" element={<Pretrip />} />
                                <Route path="weekly/:checkId" element={<Check />} />
                                <Route path="full/:checkId" element={<FullCheck />} />

                                {hasPermission(currentUser, 'editChecks.write') && (
                                    <>
                                        <Route path="weekly/edit" element={<EditChecks />} />
                                        <Route path="full/edit" element={<EditFullChecks />} />
                                    </>
                                )}
                            </Route>
                        </Route>
                    )}

                    <Route path="matrix" element={<Navigate to="/" replace />} />
                    <Route path="matrix/qualification" element={<QualificationMatrix />} />
                    <Route path="matrix/training" element={<TrainingMatrix />} />

                    <Route path="calendar" element={<Calendar />} />

                    <Route path="nfpa" element={<NFPA />} />
                    <Route path="nfpa/:standard" element={<NFPA />} />
                    <Route path="nfpa/:standard/:edition" element={<NFPA />} />

                    {hasPermission(currentUser, 'gear.read') && (
                        <>
                            <Route path="gear" element={<Gear />} />
                            {generateGearRoutes()}
                        </>
                    )}
                                                
                    {!isStation && hasFeature('incidents') && (hasPermission(currentUser, 'incidents.stats') || hasPermission(currentUser, 'incidents.read')) && (
                        <Route path="incidents">
                            <Route index element={<Incidents />} />

                            {hasPermission(currentUser, 'incidents.write') && (
                                <>
                                    <Route path="add" element={<Incident />} />
                                    <Route path=":id" element={<Incident />} />
                                    <Route path="import" element={<ImportIncidents />} />
                                </>
                            )}
                        </Route>
                    )}

                    <Route path="jibcSkills">
                        <Route index element={<SkillSheet />} />
                    </Route>

                    {(hasPermission(currentUser, 'training.stats') || hasPermission(currentUser, 'training.read')) && hasFeature('training') && (
                        <Route path="training">
                            <Route index element={<Trainings />} />

                            {hasPermission(currentUser, 'training.write') && (
                                <>
                                    <Route path="add" element={<Training />} />
                                    <Route path=":id" element={<Training />} />
                                    <Route path="import" element={<ImportTraining />} />
                                    <Route path="keywords" element={<TrainingKeywords />} />
                                </>
                            )}
                        </Route>
                    )}

                    {hasPermission(currentUser) && hasFeature('vectorSolutionsSync') && (
                        <Route path="vs">
                            <Route path="activities" element={<Activities />} />
                        </Route>
                    )}

                    {hasPermission(currentUser, 'stations.write') && (
                        <Route path="stations" element={<Stations />} />
                    )}

                    {hasPermission(currentUser, 'payroll.read') && (
                        <Route path="payroll" element={<Payroll />} />
                    )}

                    {hasPermission(currentUser, 'driverLogs.read') && (
                        <>
                            <Route path="drivertraining" element={<Navigate to="/" replace />} />
                            <Route path="drivertraining/logs" element={<DriverLogs />} />
                        </>
                    )}

                    {hasPermission(currentUser) && (
                        <Route path="settings" element={<Settings />} />
                    )}

                    {hasPermission(currentUser) && (
                        <Route path="tasks" element={<Tasks />} />
                    )}

                    {hasPermission(currentUser, 'users.write') && (
                        <>
                            <Route path="users">
                                <Route path=":id" element={<Member />} />
                                <Route path=":id/qualifications" element={<Member activeTab="qualifications" />} />
                                {hasPermission(currentUser, 'training.read') && <Route path=":id/training" element={<Member activeTab="training" />} />}
                                <Route path=":id/statistics" element={<Member activeTab="statistics" />} />
                                <Route path=":id/transcript" element={<Member activeTab="transcript" />} />
                                <Route path=":id/jibcConsent" element={<Member activeTab="jibcConsent" />} />
                                {hasPermission(currentUser) && <Route path=":id/permissions" element={<Member activeTab="permissions" />} />}
                                {hasPermission(currentUser) && <Route path=":id/drivertraining" element={<Member activeTab="drivertraining" />} />}
                                <Route index element={<Members />} />
                                <Route path="add" element={<Member />} />
                            </Route>
                        </>
                    )}

                    <Route path="respond">
                        <Route index element={<Respond />} />
                        <Route path=":id" element={<RespondMap />} />
                    </Route>
                </Route>
            )}
        </Routes>
    );
};